import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const Response: React.FC = () => {

  return (
    <Container>
      <Wrapper>
        <First>
          <Top></Top>
          <h2>Rental Application Form</h2>
          <Below>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ marginLeft: '3px', marginTop: '1px', fontSize: '20px', color: '#5F6368', display: 'flex', alignItems: 'center' }}>
                <p style={{fontSize: "16px"}}>Your response has been recorded.</p>
              </div>
            </div>
          </Below>
                  <NavLink to="/">
                      <Text>Submit another response</Text>
          </NavLink>
        </First>

        <Pl>This content is neither created nor endorsed by Google. <span>Report Abuse</span> - <span>Terms of Service</span> - <span>Privacy Policy</span></Pl>
        <Pe><span>Google</span> Forms</Pe>
      </Wrapper>
    </Container>
  );
};

export default Response;
const Below = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const Pe = styled.div`
  color: #6B696F;
  font-size: 22px;
  width: 100%;
  display: flex;
  justify-content: center;
  span{
    font-weight: 500;
  }
`
const Pl = styled.p`
  color: #6B696F;
  font-size: 13px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  span{
    text-decoration: underline;
    margin-right: 5px;
    margin-left: 5px;
  }
  @media screen and (max-width: 425px) {
    
  }
`

const Text = styled.div`
  color: #673AB7;
  margin-top: 10px;
  text-decoration: underline;
  font-size: 16px;
  margin-left: 18px;
`;

const Top = styled.div`
  width: 100%;
  height: 15px;
  background-color: #673AB7;
  margin-top: -4px;
`;

const First = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding-bottom: 22px;
  border: 1px solid #d8bfff;
  h2 {
    margin-left: 18px;
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 0px;
    margin-top: 12px;
  }
  p {
    font-size: 14px;
    margin-left: 18px;
    margin-top: 15px;
  }
`;

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 48%;
  margin-bottom: 14px;
  height: 100%;
  padding-top: 12px;
  @media screen and (max-width: 768px) {
    width: 80%;
  }
  @media screen and (max-width: 425px) {
    width: 95%;
  }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: #F0EBF8;
  flex-direction: column;
  align-items: center;
`;
